import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

import '../../styles/fellowship-application.css'

const FellowshipApp = ({ data }) => {
  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        <div className="center-text">
          {data.markdownRemark.frontmatter.blurb ? <ReactMarkdown children={data.markdownRemark.frontmatter.blurb} /> : null}
        </div>
        <form name="fellowshipApplication" method="POST" data-netlify="true" data-netlify-honeypot="bot-field"
          className="application">
          <input type="hidden" name="form-name" value="fellowshipApplication" />
          {/* <div>
            <label className="form-label" htmlFor="fellowship">Which fellowship are you applying for?</label>
            <select id="fellowship">
              <option value="">Select one</option>
              <option value="OGOV">One Girl One Vote</option>
              <option value="DIA">DIA</option>
              <option value="EL">EL</option>
            </select>
          </div> */}
          <div>
            <label className="form-label" htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" name="firstName" required />
          </div>
          <div>
            <label className="form-label" htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" name="lastName" required />
          </div>
          <div>
            <label className="form-label" htmlFor="age">Age</label>
            <input type="number" id="age" name="age" />
          </div>
          <div>
            <label className="form-label" htmlFor="email">Email Address</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div>
            <label className="form-label" htmlFor="phone">Phone Number</label>
            <input type="tel" id="phone" name="phone" />
          </div>
          <div>
            <label className="form-label" htmlFor="pronouns">Preferred Pronouns</label>
            <input type="text" id="pronouns" name="pronouns" />
          </div>
          <div>
            <label className="form-label" htmlFor="cityCurrent">What city do you currently live in?</label>
            <input type="text" id="cityCurrent" name="currentCity" />
          </div>
          <div>
            <label className="form-label" htmlFor="cityFrom">What city are you from?</label>
            <input type="text" id="cityFrom" name="fromCity" />
          </div>
          <div>
            <fieldset>
              <legend className="form-label">Do you have reliable transportation?</legend>
              <label>
                <input type="radio" value="yes" name="transportation" /> Yes
              </label>
              <label>
                <input type="radio" value="no" name="transportation" /> No
              </label>
            </fieldset>
          </div>
          <div>
            <label className="form-label" htmlFor="school">What school do you attend? <small>(put N/A if not in school)</small></label>
            <input type="text" id="school" name="schoolName" />
          </div>
          <div>
            <label className="form-label" htmlFor="major">What is your major? <small>(put N/A if not in school)</small></label>
            <input type="text" id="major" name="major" />
          </div>
          <div>
            <label className="form-label" htmlFor="gradDate">What is your expected graduation date? <small>(put N/A if not in school)</small></label>
            <input type="text" id="gradDate" name="graduationDate" />
          </div>
          <div>
            <label className="form-label" htmlFor="fellowshipType">What fellowship are you applying for?</label>
            <input type="text" id="fellowshipType" name="fellowshipType" />
          </div>
          <div>
            <fieldset>
              <legend className="form-label">The Emerging Leaders Fellowship lasts for one full year. Will you be able to fulfill that time commitment?</legend>
              <label>
                <input type="radio" value="yes" name="timeCommitment" /> Yes
              </label>
              <label>
                <input type="radio" value="no" name="timeCommitment" /> No
              </label>
            </fieldset>
          </div>
          <div>
            <label className="form-label" htmlFor="reason">Why do you want to be a part of the MS Votes team as an Emerging Leader Fellow?</label>
            <textarea id="reason" name="reasonForELF"></textarea>
          </div>
          <div>
            <label className="form-label" htmlFor="experience">What experience do you have working on social justice issues and/or political issues?</label>
            <textarea id="experience" name="experienceSocialJustice"></textarea>
          </div>
          <div>
            <fieldset>
              <legend className="form-label">Have you ever written a piece (op-ed, research, election analysis, etc.) on voting?</legend>
              <label>
                <input type="radio" value="yes" name="writtenPiece" /> Yes
              </label>
              <label>
                <input type="radio" value="no" name="writtenPiece" /> No
              </label>
            </fieldset>
          </div>
          {/* Show question if yes */}
          <div>
            <label className="form-label" htmlFor="writtenWork">Upload your written piece.</label>
            <input type="file" name="writtenWork" id="writtenWork" />
          </div>
          <div>
            <label className="form-label" htmlFor="organizingYoungFolk">What experience do you have with organizing young people?</label>
            <textarea id="organizingYoungFolk" name="organizingYoungPeople"></textarea>
          </div>
          <div>
            <label className="form-label" htmlFor="organizingMS">What, if any, experience do you have with organizing Mississippians around voting issues?</label>
            <textarea id="organizingMS" name="organizingMississippians"></textarea>
          </div>
          {/* Add link to the mission */}
          <div>
            <label className="form-label" htmlFor="mission">What does MS Votes's mission of "cultivating a culture of transfomative civic engagement" mean to you?</label>
            <textarea id="mission" name="msVotesMission"></textarea>
          </div>
          <div>
            <fieldset>
              <legend className="form-label">What skills do you wish to gain through this fellowship? <small>(Check all that apply)</small></legend>
              <label>
                <input type="checkbox" name="skills[]" value="Using the Voter Access Network" /> Using the Voter Access Network (VAN)
              </label>
              <label>
                <input type="checkbox" name="skills[]" value="Field Work" /> Field Work (Canvassing and Planning)
              </label>
              <label>
                <input type="checkbox" name="skills[]" value="Organizing a Voter Registration Drive" /> Organizing a Voter Registration Drive
              </label>
              <label>
                <input type="checkbox" name="skills[]" value="Communications" /> Communications
              </label>
              <label>
                <input type="checkbox" name="skills[]" value="Data Management" /> Data Management
              </label>
              <label>
                <input type="checkbox" name="skills[]" value="Community Organizing" /> Community Organizing
              </label>
              <label>
                <input type="checkbox" name="skills[]" value="Policy and Research" /> Policy and Research
              </label>
              <label>
                <input type="checkbox" name="skills[]" value="Youth Civic Engagement" /> Youth Civic Engagement
              </label>
              <label>
                <input type="checkbox" name="skills[]" value="other" /> Other (list below)
              </label>
            </fieldset>
          </div>
          {/* Show question if other */}
          <div>
            <label className="form-label" htmlFor="otherSkills">Other skills you wish to gain through this fellowship</label>
            <input type="text" id="otherSkills" name="otherSkills" />
          </div>
          <div>
            <label className="form-label" htmlFor="skillsBring">What skills can you bring to MS Votes?</label>
            <textarea id="skillsBring" name="whatSkillsToMSVotes"></textarea>
          </div>
          <div>
            <fieldset>
              <legend className="form-label">Are you willing to travel?</legend>
              <label>
                <input type="radio" value="yes" name="travel" /> Yes
              </label>
              <label>
                <input type="radio" value="no" name="travel" /> No
              </label>
            </fieldset>
          </div>
          <div>
            <label className="form-label" htmlFor="resume">Resume</label>
            <input type="file" id="resume" name="resume" />
          </div>
          <div>
            <label className="form-label" htmlFor="coverLetter">Cover Letter <small>(Please include three references)</small></label>
            <input type="file" id="coverLetter" name="coverLetter" />
          </div>
          <div>
            <label className="form-label" htmlFor="headshot">Headshot</label>
            <input type="file" id="headshot" name="headshot" />
          </div>
          <div className="flex-row btn-row">
            <button className="cta-btn" type="submit">Submit</button>
          </div>
        </form>
      </div >
    </Layout >
  )
}

export const pageQuery = graphql`query FellowshipApplicationQuery {
  markdownRemark(frontmatter: {title: {eq: "Fellowship Application"}}) {
    frontmatter {
      heading
      intro
      blurb
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
    }
  }
}
`

export default FellowshipApp